<script setup>

</script>

<template>
  <div class="text-xs mt-4 text-gray-400 px-3">
    This site is protected by reCAPTCHA and the Google
    <a href="https://policies.google.com/privacy">Privacy Policy</a> and
    <a href="https://policies.google.com/terms">Terms of Service</a> apply.
  </div>
</template>

<style scoped>

</style>
